import { EComponentTypenames, ICollection, ISysCfl } from "@shared/contentful/contentful.types";
import { IJsonDocument } from "@components/ContentfulComponents/ComponentRichText/type";
import { IAssetCflData } from "../ComponentAsset/type";
import { ILinkCflData } from "../ComponentLink/type";

export enum ETextAssetAndCtasCflDisplayOptions {
	HeroBanner = "hero banner",
	HighlightText = "highlight text",
	TextImageCta = "text image cta",
	TextImage = "text and image",
	RichText = "static pages: rich text",
}

export enum ETextAssetAndCtasCflBgColor {
	White = "white",
	Grey = "grey",
	LightPurple = "light purple",
}

export enum ETextAssetAndCtasCflTextAlignment {
	Left = "left",
	Center = "center",
}

export enum ETextAssetAndCtasCflAssetPosition {
	Left = "left",
	Right = "right",
}

export interface ITextAssetAndCtasCflData extends ISysCfl {
	__typename: EComponentTypenames;
	identifier?: string;
	displayAs?: ETextAssetAndCtasCflDisplayOptions;
	backgroundColor?: ETextAssetAndCtasCflBgColor;
	overlineText?: string;
	title?: string;
	openLinksInANewTab?: boolean | null;
	richTextContent?: IJsonDocument;
	textAlignment?: ETextAssetAndCtasCflTextAlignment;
	asset?: IAssetCflData;
	assetLink?: ILinkCflData;
	assetPosition?: ETextAssetAndCtasCflAssetPosition;
	ctasCollection?: ICollection<ILinkCflData>;
	textColor?: string;
}
