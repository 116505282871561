import React from "react";
import dynamic from "next/dynamic";

import {
	ETextAssetAndCtasCflDisplayOptions,
	ITextAssetAndCtasCflData,
} from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";

interface IProps {
	data: ITextAssetAndCtasCflData;
	absoluteUrl?: string;
}

const DynamicHeroBanner = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.component"
		)
);
const DynamicHighlightText = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/HighlightText/HighlightText.component"
		)
);
const DynamicTextImage = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/TextImage/TextImage.component"
		)
);
const DynamicTextImageCta = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/TextImageCta/TextImageCta.component"
		)
);
const DynamicRichTextAndCtas = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/RichTextAndCtas/RichTextAndCtas.component"
		)
);

const TextAssetAndCtas = ({ data, absoluteUrl }: IProps) => {
	if (!data) return null;

	const {
		displayAs,
		asset,
		assetLink,
		assetPosition,
		backgroundColor,
		ctasCollection,
		overlineText,
		openLinksInANewTab,
		richTextContent,
		textAlignment,
		title,
		textColor,
	} = data;

	switch (displayAs) {
		case ETextAssetAndCtasCflDisplayOptions.HeroBanner:
			return (
				<DynamicHeroBanner
					asset={asset}
					richTextContent={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					ctasCollection={ctasCollection}
					data-testid="TextAssetAndCtas_hero-banner"
					textColor={textColor}
				/>
			);
		case ETextAssetAndCtasCflDisplayOptions.HighlightText:
			return (
				<DynamicHighlightText
					overlineText={overlineText}
					richTextContent={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					ctasCollection={ctasCollection}
					data-testid="TextAssetAndCtas_highlight-text"
				/>
			);
		case ETextAssetAndCtasCflDisplayOptions.TextImage:
			return (
				<DynamicTextImage
					asset={asset}
					assetPosition={assetPosition}
					backgroundColor={backgroundColor}
					ctasCollection={ctasCollection}
					richTextContent={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					title={title}
					data-testid="TextAssetAndCtas_text-image"
				/>
			);
		case ETextAssetAndCtasCflDisplayOptions.TextImageCta:
			return (
				<DynamicTextImageCta
					asset={asset}
					assetLink={assetLink}
					title={title}
					ctasCollection={ctasCollection}
					richTextContent={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					absoluteUrl={absoluteUrl}
					data-testid="TextAssetAndCtas_textimage-cta"
				/>
			);
		case ETextAssetAndCtasCflDisplayOptions.RichText:
			return (
				<DynamicRichTextAndCtas
					backgroundColor={backgroundColor}
					ctasCollection={ctasCollection}
					richTextContent={richTextContent}
					openLinksInANewTab={openLinksInANewTab}
					textAlignment={textAlignment}
					data-testid="TextAssetAndCtas_rich-text-and-ctas"
				/>
			);
		default:
			return null;
	}
};

export default TextAssetAndCtas;
