import React from "react";

import TextAssetAndCtas from "@components/ContentfulComponents/ComponentTextAssetAndCtas/TextAssetAndCtas.component";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { isTextAssetCtas } from "@shared/contentful/contentful.utils";

import styles from "@components/ContentfulComponents/ContentPagesContentBlock/ContentBlockComponents/TextAssetAndCtasBlock/text-asset-ctas-block.module.scss";

interface IProps {
	data: ITextAssetAndCtasCflData[];
	absoluteUrl?: string;
}

const TextAssetAndCtasBlock = ({ data, absoluteUrl }: IProps) => {
	if (!data || data.length === 0) return null;

	return (
		<div className={styles.wrapper}>
			{data.map((item) => {
				if (!item || !isTextAssetCtas(item)) return null;
				return <TextAssetAndCtas key={item.sys?.id} data={item} absoluteUrl={absoluteUrl} />;
			})}
		</div>
	);
};

export default TextAssetAndCtasBlock;
